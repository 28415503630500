
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import list from "@/components/common/list.vue";
import productCard from "@/components/common/productCard.vue";
import Toast from "@/components/UI/Toast/";

//ts
import {productMap} from "@/hooks/useProduct";
import $ from "jquery";

export default defineComponent({
  name: "OpenSearch",
  components: {
    productCard,
    list,
  },
  setup() {


    const route = useRoute();
    const type = route.query.type;


    const keyword = route.query.keyword;

    /*const searchKw = ref(null);

    $.ajax({
      type: "get",
      url: "/M/Home/accurateSearch?keyword="+keyword,
      data:searchKw,
      processData: false,
      contentType: false,
      success: function (data)  {
        searchKw.value=data.success;
      },
      error: function (err) {
        // errorToast(err)
      }
    })*/

    return {
      productMap,
      keyword,
    };
  },
});
