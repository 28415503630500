<template>

  <list
      url="/M/Home/OpenSearchMore"
      :params="{ type: 'product', keyword: keyword }"
      :filterFunc="productMap"
      :show="true"
  >
    <template v-slot:default="data">
      <product-card
          :list="data"
          :totalResult="totalResult"
          name="productdetail"
          tid="0"
      ></product-card>
    </template>
  </list>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import list from "@/components/common/list.vue";
import productCard from "@/components/common/productCard.vue";
import Toast from "@/components/UI/Toast/";

//ts
import {productMap} from "@/hooks/useProduct";
import $ from "jquery";

export default defineComponent({
  name: "OpenSearch",
  components: {
    productCard,
    list,
  },
  setup() {


    const route = useRoute();
    const type = route.query.type;


    const keyword = route.query.keyword;

    /*const searchKw = ref(null);

    $.ajax({
      type: "get",
      url: "/M/Home/accurateSearch?keyword="+keyword,
      data:searchKw,
      processData: false,
      contentType: false,
      success: function (data)  {
        searchKw.value=data.success;
      },
      error: function (err) {
        // errorToast(err)
      }
    })*/

    return {
      productMap,
      keyword,
    };
  },
});
</script>

<style lang="scss" scoped>
  a {
    color: #000;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  li {
    list-style: none;
  }

  .search_kefu{
    text-align: center;
    color: #df0024;
    font-size: 0.26rem;
    cursor: pointer;
    border-bottom: 1px solid #df0024;
  }
  .kw_tip{
    border: 1px dashed rgb(153, 153, 153);
    width: 9.4rem;
    margin: 0.3rem;
    height: 35px;
    line-height: 35px;
    padding-left: 8px;
    color: #666;
    font-size: 0.26rem;
  }

</style>
